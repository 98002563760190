import React from 'react'

const Grids = () => {
    return (
        <div className="grids d-flex justify-content-between">
            <div className="grid1"></div>
            <div className="grid2"></div>
            <div className="grid3"></div>
            <div className="grid4"></div>
            <div className="grid5"></div>
            <div className="grid6"></div>
            <div className="grid7"></div>
            <div className="grid8"></div>
            <div className="grid9"></div>
        </div>
    )
}

export default Grids;