import React, { Fragment } from 'react'
import ReactAudioPlayer from 'react-audio-player'

const Inicio = () => {
    
    return (
    	<Fragment>

    		<ReactAudioPlayer
			  src="/assets/audio/song.mp3"
			  className="song"
			  autoPlay={false}
			  controls={false}
			/>

	    	<div className="portfolio-area section-padding-100">
		        <div className="container">
		            <div className="row">
		                <div className="col-12">
		                    <div className="portfolio-title">
		                        <h2>“Nuestra historia comenzó hace 5 años, pero cada día <span>a tu lado</span> lo disfruto como el primero.”</h2>
		                    </div>
		                </div>
		            </div>

		            <div className="row justify-content-between">
		                <div className="col-12 col-md-5">
		                    <div className="single-portfolio-item mt-100 portfolio-item-1 wow fadeIn">
		                        <div className="backend-content">
		                            <img className="dots" src="assets/img/core-img/dots.png" alt="" />
		                            <h2>Amor</h2>
		                        </div>
		                        <div className="portfolio-thumb">
		                            <img src="assets/img/bg-img/p1.jpg" alt="" />
		                        </div>
		                        <div className="portfolio-meta">
		                            <p className="portfolio-date">15 marzo, 2015</p>
		                            <h2>El viaje de graduación donde nos conocimos</h2>
		                        </div>
		                    </div>
		                </div>
		                <div className="col-12 col-md-6">
		                    <div className="single-portfolio-item mt-230 portfolio-item-2 wow fadeIn">
		                        <div className="backend-content">
		                            <img className="dots" src="assets/img/core-img/dots.png" alt="" />
		                        </div>
		                        <div className="portfolio-thumb">
		                            <img src="assets/img/bg-img/p2.jpg" alt="" />
		                        </div>
		                        <div className="portfolio-meta">
		                            <p className="portfolio-date">26 noviembre, 2015</p>
		                            <h2>Nuestro primer viaje juntos</h2>
		                        </div>
		                    </div>
		                </div>
		            </div>

		            <div className="row">
		                <div className="col-12 col-md-10">
		                    <div className="single-portfolio-item mt-100 portfolio-item-3 wow fadeIn">
		                        <div className="backend-content">
		                            <img className="dots" src="assets/img/core-img/dots.png" alt="" />
		                            <h2>Love</h2>
		                        </div>
		                        <div className="portfolio-thumb">
		                            <img src="assets/img/bg-img/p3.jpg" alt="" />
		                        </div>
		                        <div className="portfolio-meta">
		                            <p className="portfolio-date">11 enero, 2016</p>
		                            <h2>Nuestra primer pelea :(</h2>
		                        </div>
		                    </div>
		                </div>
		            </div>

		            <div className="row justify-content-end">
		                <div className="col-12 col-md-6">
		                    <div className="single-portfolio-item portfolio-item-4 wow fadeIn">
		                        <div className="backend-content">
		                            <img className="dots" src="assets/img/core-img/dots.png" alt="" />
		                        </div>
		                        <div className="portfolio-thumb">
		                            <img src="assets/img/bg-img/p4.jpg" alt="" />
		                        </div>
		                        <div className="portfolio-meta">
		                            <p className="portfolio-date">18 abril, 2017</p>
		                            <h2>Segundo aniversario :D</h2>
		                        </div>
		                    </div>
		                </div>
		            </div>

		            <div className="row">
		                <div className="col-12 col-md-5">
		                    <div className="single-portfolio-item portfolio-item-5 wow fadeIn">
		                        <div className="backend-content">
		                            <img className="dots" src="assets/img/core-img/dots.png" alt="" />
		                            <h2>Amour</h2>
		                        </div>
		                        <div className="portfolio-thumb">
		                            <img src="assets/img/bg-img/p5.jpg" alt="" />
		                        </div>
		                        <div className="portfolio-meta">
		                            <p className="portfolio-date">3 septiembre, 2017</p>
		                            <h2>Viendo nuestra primer serie juntos</h2>
		                        </div>
		                    </div>
		                </div>
		            </div>

		            <div className="row justify-content-center">
		                <div className="col-12 col-md-4">
		                    <div className="single-portfolio-item portfolio-item-6 wow fadeIn">
		                        <div className="portfolio-thumb">
		                            <img src="assets/img/bg-img/p6.jpg" alt="" />
		                        </div>
		                        <div className="portfolio-meta">
		                            <p className="portfolio-date">25 julio, 2018</p>
		                            <h2>Cuando recien nos mudamos</h2>
		                        </div>
		                    </div>
		                </div>
		            </div>

		            <div className="row justify-content-end">
		                <div className="col-12 col-md-4">
		                    <div className="single-portfolio-item portfolio-item-7 wow fadeIn">
		                        <div className="backend-content">
		                            <img className="dots" src="assets/img/core-img/dots.png" alt="" />
		                            <h2>Liebe</h2>
		                        </div>
		                        <div className="portfolio-thumb">
		                            <img src="assets/img/bg-img/p7.jpg" alt="" />
		                        </div>
		                        <div className="portfolio-meta">
		                            <p className="portfolio-date">18 abril, 2019</p>
		                            <h2>Cuarto aniversario ;D</h2>
		                        </div>
		                    </div>
		                </div>
		            </div>
		        </div>
		    </div>

		    <div className="hero-area d-flex align-items-center">
		        <div className="hero-thumbnail equalize bg-img" style={{ backgroundImage: "url('assets/img/bg-img/principal.jpg')", height: "880px" }}></div>
		        
		        <div className="hero-content equalize">
		            <div className="container-fluid h-100">
		                <div className="row h-100 align-items-center justify-content-center">
		                    <div className="col-12 col-md-8">
		                        <div className="line"></div>
		                        <h2>La vida sabe mejor cuando la compartes</h2>
		                        <p>Y compartirla contigo ha sido y seguirá siendo lo más perfecto del mundo, feliz aniversario, TE AMO.</p>
		                    </div>
		                </div>
		            </div>
		        </div>
		    </div>

		    <section className="sonar-projects-area" id="projects">
		        <div className="container-fluid">
		            <div className="row sonar-portfolio">

		                <div className="col-12 col-sm-6 col-lg-3 single_gallery_item landscapes studio wow fadeInUpBig" data-wow-delay="300ms">
		                    <a className="gallery-img" href="assets/img/portfolio-img/1.jpg"><img src="assets/img/portfolio-img/1.jpg" alt="" /></a>
		                </div>

		                <div className="col-12 col-sm-6 col-lg-3 single_gallery_item portraits fashion wow fadeInUpBig" data-wow-delay="500ms">
		                    <a className="gallery-img" href="assets/img/portfolio-img/2.jpg"><img src="assets/img/portfolio-img/2.jpg" alt="" /></a>
		                </div>

		                <div className="col-12 col-sm-6 col-lg-3 single_gallery_item landscapes studio wow fadeInUpBig" data-wow-delay="700ms">
		                    <a className="gallery-img" href="assets/img/portfolio-img/3.jpg"><img src="assets/img/portfolio-img/3.jpg" alt="" /></a>
		                </div>

		                <div className="col-12 col-sm-6 col-lg-3 single_gallery_item portraits studio wow fadeInUpBig" data-wow-delay="900ms">
		                    <a className="gallery-img" href="assets/img/portfolio-img/4.jpg"><img src="assets/img/portfolio-img/4.jpg" alt="" /></a>
		                </div>

		                <div className="col-12 col-sm-6 col-lg-3 single_gallery_item landscapes fashion wow fadeInUpBig" data-wow-delay="300ms">
		                    <a className="gallery-img" href="assets/img/portfolio-img/5.jpg"><img src="assets/img/portfolio-img/5.jpg" alt="" /></a>
		                </div>

		                <div className="col-12 col-sm-6 col-lg-3 single_gallery_item portraits fashion wow fadeInUpBig" data-wow-delay="500ms">
		                    <a className="gallery-img" href="assets/img/portfolio-img/6.jpg"><img src="assets/img/portfolio-img/6.jpg" alt="" /></a>
		                </div>

		                <div className="col-12 col-sm-6 col-lg-3 single_gallery_item landscapes fashion wow fadeInUpBig" data-wow-delay="700ms">
		                    <a className="gallery-img" href="assets/img/portfolio-img/7.jpg"><img src="assets/img/portfolio-img/7.jpg" alt="" /></a>
		                </div>

		                <div className="col-12 col-sm-6 col-lg-3 single_gallery_item portraits studio wow fadeInUpBig" data-wow-delay="900ms">
		                    <a className="gallery-img" href="assets/img/portfolio-img/8.jpg"><img src="assets/img/portfolio-img/8.jpg" alt="" /></a>
		                </div>
		            </div>
		        </div>
		    </section>
	    </Fragment>
    )
}

export default Inicio;