import React from 'react'

const Header = () => {
    return (
        <header className="header-area">
	        <div className="container-fluid">
	            <div className="row">
	                <div className="col-12">
	                    <div className="menu-area d-flex justify-content-between">
	                        <div className="logo-area">
	                            <a href="#">
	                            	<img id="logo-color" className="logo-header" src="assets/img/core-img/logo-alt.png" alt="" />
	                            	<img id="logo-blanco" className="logo-header d-none" src="assets/img/core-img/logo.png" alt="" />
                            	</a>
	                        </div>

	                        <div className="menu-content-area d-flex align-items-center">
	                        	<button id="boton-audio" className="boton-oscuro"><i id="icono-audio" className="fa fa-pause-circle" aria-hidden="true"></i></button>
	                        </div>
	                    </div>
	                </div>
	            </div>
	        </div>
	    </header>
    )
}

export default Header;