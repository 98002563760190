import React from 'react'

const Footer = () => {
    return (
        <footer className="footer-area">
	        <div className="container">
	            <div className="row">
	                <div className="col-12">
	                    <div className="copywrite-text">
	                        <p>Hecho con <i className="fa fa-heart-o" aria-hidden="true"></i> por <a href="https://duitalles.com" target="_blank">Duitalles</a></p>
	                    </div>
	                </div>
	            </div>
	        </div>
	    </footer>
    )
}

export default Footer;