import React from 'react'

const Loader = () => {
    return (
	    <div id="preloader">
	        <div className="preload-content">
	            <div id="sonar-load"></div>
	        </div>
	    </div>
    )
}

export default Loader;