import React from 'react'

const Cobertura = () => {
    return (
	    <div id="cobertura">
	    	<img id="regalo-sorpresa" className="pulse" src="assets/img/core-img/regalo.png" alt="" />
	    </div>
    )
}

export default Cobertura;