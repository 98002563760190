import React from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import Inicio from './Views/Inicio'

import Loader from './Components/Layout/Loader'
import Cobertura from './Components/Layout/Cobertura'
import Grids from './Components/Layout/Grids'
import Header from './Components/Layout/Header'
import Footer from './Components/Layout/Footer'

function App() {
  return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <Loader />
                    <Cobertura />
                    <Grids />
                    <Header />
                    <Inicio />
                    <Footer />
                </Route>
                <Route path="/">
                    <Loader />
                    <Cobertura />
                    <Grids />
                    <Header />
                    <Inicio />
                    <Footer />
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
